import {useForm} from "react-hook-form";
import {AuthContext} from "../../store/auth-context";
import {useContext, useEffect, useState} from "react";
import {MessageContext} from "../../store/message-context";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MdCardGiftcard} from "react-icons/md";
import {resetPassword} from "../../util/auth";
import axios from "axios";


function AuthForm({isLogin, onAuthenticate, pseudos}) {
    
    const {t} = useTranslation();
    
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    
    const watchedEmail = watch("email");
    const watchedPassword = watch("password");

    function forgotPasswordHandler() {
        const response = resetPassword(watchedEmail);
    }
    
    const onSubmit = ({email, password, pseudo}) => onAuthenticate({email, password, pseudo});
    
    return (
        
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <MdCardGiftcard
                    className="mx-auto h-12 w-auto"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    {isLogin ? t('Auth.loginToAccount') : t('Auth.signup')}
                </h2>
            </div>
            
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t('Auth.emailAdress')} *
                            </label>
                            <div className="mt-1">
                                <input
                                    {...register("email", {required: true, maxLength: 30})}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                    placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                    focus:ring-indigo-500 sm:text-sm"
                                />
                                {errors.email?.type === 'required' &&
                                    <p className="text-red-500">{t('Form.emailRequired')}</p>}
                                {errors.email?.type === 'maxLength' &&
                                    <p className="text-red-500">{t('Form.emailTooLong')}</p>}
                            </div>
                        </div>
                        
                        {!isLogin && <div>
                            <label htmlFor="confirmEmail" className="block text-sm font-medium text-gray-700">
                                {t('Auth.confirmEmail')} *
                            </label>
                            <div className="mt-1">
                                <input
                                    {...register("confirmEmail", {
                                        required: true,
                                        maxLength: 30,
                                        validate: {notSameEmail: value => value === watchedEmail}
                                    })}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                    placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                    focus:ring-indigo-500 sm:text-sm"
                                />
                                {errors.confirmEmail?.type === 'required' &&
                                    <p className="text-red-500">{t('Form.confirmEmailRequired')}</p>}
                                {errors.confirmEmail?.type === 'maxLength' &&
                                    <p className="text-red-500">{t('Form.confirmEmailTooLong')}</p>}
                                {errors.confirmEmail?.type === 'notSameEmail' &&
                                    <p className="text-red-500">{t('Form.notSameEmails')}</p>}
                            </div>
                        </div>}
                        
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                {t('Auth.password')} *
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    {...register("password",
                                        {
                                            required: true, minLength: 6,
                                            maxLength: 30,
                                        },
                                    )}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                    placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                    focus:ring-indigo-500 sm:text-sm"
                                />
                                {errors.password?.type === 'required' &&
                                    <p className="text-red-500">{t('Form.passwordRequired')}</p>}
                                {errors.password?.type === 'minLength' &&
                                    <p className="text-red-500">{t('Form.passwordTooShort')}</p>}
                                {errors.password?.type === 'maxLength' &&
                                    <p className="text-red-500">{t('Form.passwordTooLong')}</p>}
                            </div>
                        </div>
                        
                        {!isLogin && <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                {t('Auth.confirmPassword')} *
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    {...register("confirmPassword",
                                        {
                                            required: true,
                                            minLength: 6,
                                            maxLength: 30,
                                            validate: value => value === watchedPassword
                                        })}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                    placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                    focus:ring-indigo-500 sm:text-sm"
                                />
                                {errors.confirmPassword?.type === 'required' &&
                                    <p className="text-red-500">{t('Form.confirmPasswordRequired')}</p>}
                                {errors.confirmPassword?.type === 'minLength' &&
                                    <p className="text-red-500">{t('Form.confirmPasswordTooShort')}</p>}
                                {errors.confirmPassword?.type === 'maxLength' &&
                                    <p className="text-red-500">{t('Form.confirmPasswordTooLong')}</p>}
                                {errors.confirmPassword?.type === 'validate' &&
                                    <p className="text-red-500">{t('Form.notSamePasswords')}</p>}
                            </div>
                        </div>}
                        
                        {isLogin && <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <button onClick={forgotPasswordHandler}
                                        className="font-medium text-primary hover:text-secondary">
                                    {t('AuthForm.forgotPassword')}
                                </button>
                            </div>
                        </div>}
    
                        {!isLogin && <div>
                            <label htmlFor="pseudo" className="block text-sm font-medium text-gray-700">
                                Pseudo * ({t('Form.noSpecialCharacter')})
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    {...register("pseudo",
                                        {
                                            required: true,
                                            minLength: 3,
                                            maxLength: 30,
                                            pattern: /^[a-zA-Z0-9]*$/,
                                            validate: value => !pseudos.includes(value)
                                        })}
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                    placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                    focus:ring-indigo-500 sm:text-sm"
                                />
                                {errors.pseudo?.type === 'required' &&
                                    <p className="text-red-500">{t('Form.pseudoRequired')}</p>}
                                {errors.pseudo?.type === 'minLength' &&
                                    <p className="text-red-500">{t('Form.pseudoTooShort')}</p>}
                                {errors.pseudo?.type === 'maxLength' &&
                                    <p className="text-red-500">{t('Form.pseudoTooLong')}</p>}
                                {errors.pseudo?.type === 'validate' &&
                                    <p className="text-red-500">{t('Form.pseudoAlreadyUsed')}</p>}
                                {errors.pseudo?.type === 'pattern' &&
                                    <p className="text-red-500">{t('Form.pseudoWithSpecialCharacters')}</p>}
                            </div>
                        </div>}
    
    
                        <div>
                            <button
                                type="submit"
                                className="text-white flex w-full justify-center rounded-md border border-transparent bg-primary
                                py-2 px-4 text-sm font-medium shadow-sm hover:bg-primary focus:outline-none
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {isLogin ? t('Auth.login') : t('Auth.signup')}
                            </button>
                        </div>
                    </form>
                </div>
                
                <div> * {t('Auth.requiredFields')}</div>
            </div>
        </div>
    );
}

export default AuthForm;
