import {Fragment, useContext, useEffect, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link, NavLink} from "react-router-dom";
import {AuthContext} from "../../store/auth-context";
import {MdAccountCircle, MdCardGiftcard} from "react-icons/md";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import axios from "axios";
import {MessageContext} from "../../store/message-context";
import NotificationsModal from "../Notifications/NotificationsModal";

export default function Nav() {
    
    const authCtx = useContext(AuthContext);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationsMode, setNotificationsMode] = useState(false);
    const [user, setUser] = useState(null);
    const messageCtx = useContext(MessageContext);
    
    
    useEffect(() => {
        if (authCtx.userId) {
            fetchNotifications()
        }
    }, [authCtx]);
    
    async function fetchNotifications() {
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + authCtx.userId + '.json?auth=' + authCtx.token);
            const requests = response.data;
            
            if (requests) {
                const reqArray = Object.values(requests)
                
                const req = reqArray.map(req => req);
                
                
                setNotifications(req || [])
            }
            
        } catch (e) {
            const message = e.response.data.error.message ? e.response.data.error.message : e.response.data.error;
            messageCtx.setMessage({'type': 'error', 'message': message});
        }
    }
    
    function closeModalHandler() {
        setNotificationsMode(false)
    }
    
    function notificationModeHandler() {
        if (notifications.length > 0) {
            setNotificationsMode(true);
        }
    }
    
    const navClass = (isActive) => isActive ? ' border-primary text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700';
    
    return (
        <>
            <Disclosure as="nav" className="bg-white shadow fixed w-full z-10">
                {({open}) => (<>
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                
                                <div className="flex flex-shrink-0 items-center">
                                    <Link to={'/'}>
                                        <MdCardGiftcard className="block h-8 w-auto lg:hidden"/>
                                        <MdCardGiftcard
                                            className="hidden h-8 w-auto lg:block text-primary"
                                        />
                                    </Link>
                                </div>
                                
                                {authCtx.isAuthenticated &&
                                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                        {/* Current: "border-primary text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                        <NavLink
                                            to="/lists"
                                            className={({isActive}) => classNames(['inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2', navClass(isActive)])}>
                                            {t('Nav.yourLists')}
                                        </NavLink>
                                        <NavLink
                                            to="/friends"
                                            className={({isActive}) => classNames(['inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2', navClass(isActive)])}>
                                            {t('Nav.yourFriends')}
                                        </NavLink>
                                        <NavLink
                                            to="/world"
                                            className={({isActive}) => classNames(['inline-flex items-center px-1 pt-1 text-sm font-medium border-b-2', navClass(isActive)])}>
                                            {t('Nav.world')}
                                        </NavLink>
                                    </div>
                                }
                            
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                {!authCtx.isAuthenticated && <>
                                    <NavLink to='/login'
                                             className={({isActive}) => isActive ? 'mx-2 p-2 bg-primary text-white' : 'mx-2 p-2 border-primary border'}>
                                        {t('Nav.login')}
                                    
                                    </NavLink>
                                    
                                    <NavLink to='/signUp'
                                             className={({isActive}) => isActive ? 'mx-2 p-2 bg-primary text-white' : 'mx-2 p-2 border-primary border'}
                                    >
                                        {t('Nav.signup')}
                                    </NavLink>
                                </>}
                                
                                {authCtx.isAuthenticated && <button
                                    type="button"
                                    onClick={notificationModeHandler}
                                    className={`rounded-full p-1 text-gray-400 hover:text-black
                            ${notifications.length > 0 ? 'animate-pulse ring-2 ring-primary focus:outline-none focus:ring-2 focus:ring-primary' : 'cursor-default'}
                            `}
                                >
                                    <span className="sr-only">{t('Nav.notifications')}</span>
                                    <BellIcon className={`h-5 w-5  ${notifications.length > 0 ? 'text-primary' : ''}`} aria-hidden="true"/>
                                </button>}
                                
                                {/* Profile dropdown */}
                                {authCtx.isAuthenticated &&
                                    <>
                                        <Menu as="div" className="relative ml-3">
                                            
                                            <div className="">
                                                <Menu.Button
                                                    className="flex rounded-full bg-white text-4xl focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                                                    <span className="sr-only">{t('Nav.openUserMenu')}</span>
                                                    <MdAccountCircle/>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({active}) => (<Link
                                                            to="/Login"
                                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                        >
                                                            {t('Nav.signOut')}
                                                        </Link>)}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div className="flex justify-center italic text-xl">
                                            {authCtx.userPseudo}
                                        </div>
                                    </>
                                }
                            
                            </div>
                            
                            {authCtx.isAuthenticated && (
                                <div className="-mr-2 flex items-center sm:hidden">
                                    <div className="mr-5">
                                        <button
                                            type="button"
                                            onClick={notificationModeHandler}
                                            className={`rounded-full p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-primary
                            ${notifications.length > 0 ? 'animate-pulse ring-2 ring-primary' : ''}
                            `}
                                        >
                                            <span className="sr-only">{t('Nav.notifications')}</span>
                                            <BellIcon className={`h-5 w-5  ${notifications.length > 0 ? 'text-primary' : ''}`} aria-hidden="true"/>
                                        </button>
                                    </div>
                                    
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                                        <span className="sr-only">{t('Nav.openMainMenu')}</span>
                                        {open ? (<XMarkIcon className="block h-6 w-6" aria-hidden="true"/>) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>)}
                                    </Disclosure.Button>
                                </div>
                            )}
                        
                        </div>
                    </div>
                    
                    <Disclosure.Panel className="sm:hidden">
                        {authCtx.isAuthenticated && (
                            <>
                                <div className="space-y-1 pt-2 pb-3">
                                    {/* Current: "bg-indigo-50 border-primary text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                                    
                                    <NavLink
                                        to="/lists"
                                        className={({isActive}) => classNames(['block items-center px-1 pt-1 text-sm font-medium border-l-2', navClass(isActive)])}>
                                        {t('Nav.yourLists')}
                                    </NavLink>
                                    <NavLink
                                        to="/friends"
                                        className={({isActive}) => classNames(['block items-center px-1 pt-1 text-sm font-medium border-l-2', navClass(isActive)])}>
                                        {t('Nav.yourFriends')}
                                    </NavLink>
                                    <NavLink
                                        to="/world"
                                        className={({isActive}) => classNames(['block items-center px-1 pt-1 text-sm font-medium border-l-2', navClass(isActive)])}>
                                        {t('Nav.world')}
                                    </NavLink>
                                </div>
                                
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="flex items-center px-4">
                                        <div
                                            className="flex rounded-full bg-white text-2xl focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                                            <span className="sr-only">{t('Nav.openUserMenu')}</span>
                                            <MdAccountCircle/>
                                        </div>
                                        <NavLink
                                            to="/login"
                                        >
                                            {t('Nav.signOut')}
                                        </NavLink>
                                    </div>
                                </div>
                            </>
                        
                        )}
                    </Disclosure.Panel>
                </>)}
            </Disclosure>
            
            {notificationsMode && <NotificationsModal notifications={notifications} onClose={closeModalHandler}/>}
        
        </>
    )
}
