import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from "axios";
import {MessageContext} from "./message-context";

const defaultState = {
    token: '',
    userId: '',
    userPseudo: '',
    isAuthenticated: false,
    authenticate: () => {
    },
    logout: () => {
    }
};

let logoutTimer;

export const AuthContext = createContext(defaultState);

function calculateRemainingTime(expirationTime) {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    
    return adjExpirationTime - currentTime;
}

const retrieveStoredToken = (token, expirationTime) => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');
    const storedUserId = localStorage.getItem('user-id');
    const storedUserPseudo = localStorage.getItem('user-pseudo');
    
    const remainingTime = calculateRemainingTime(storedExpirationDate);
    
    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('user-id');
        localStorage.removeItem('expirationTime');
        return null;
    }
    
    return {
        token: storedToken,
        duration: remainingTime,
        userId: storedUserId,
        userPseudo: storedUserPseudo
    }
}

function AuthContextProvider({children}) {
    const tokenData = retrieveStoredToken();
    let initialToken;
    let initialUserId;
    
    if (tokenData) {
        initialToken = tokenData.token;
        initialUserId = tokenData.userId
    }
    const [authToken, setAuthToken] = useState(initialToken);
    const [userId, setUserId] = useState(initialUserId);
    const [userPseudo, setUserPseudo] = useState(null);
    const messageCtx = useContext(MessageContext);
    
    const logout = useCallback(() => {
        setAuthToken('');
        setUserId('');
        setUserPseudo('');
        localStorage.removeItem('token');
        localStorage.removeItem('user-id');
        localStorage.removeItem('user-pseudo');
        localStorage.removeItem('expirationTime');
        
        
        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);
    
    
    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logout, tokenData.duration);
        }
    }, [tokenData, logout])
    
    
    function authenticate(authData) {
        setAuthToken(authData.idToken);
        setUserId(authData.localId);
        localStorage.setItem('token', authData.idToken);
        localStorage.setItem('user-id', authData.localId);
        
        const expirationTime = new Date(new Date().getTime() + (+authData.expiresIn * 1000));
        localStorage.setItem('expirationTime', expirationTime);
        
        const remainingTime = calculateRemainingTime(expirationTime);
        logoutTimer = setTimeout(logout, remainingTime);
        
    }
    
    useEffect(() => {
        if (userId) {
            getAndSetUser()
        }
    }, [userId, authToken])
    
    async function getAndSetUser() {
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                + userId + '.json?auth=' + authToken);
            
            const data = await response.data;
            setUserPseudo(data.pseudo);
    
            localStorage.setItem('user-pseudo', data.pseudo);
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
    }
    
    
    const value = {
        token: authToken,
        userId,
        userPseudo,
        isAuthenticated: !!authToken,
        authenticate,
        logout
    }
    
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
