import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {MessageContext} from "../../store/message-context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modal from "../UI/Modal";
import {MdCancel} from "react-icons/md";
import Spinner from "../UI/Spinner";


function AddEditListModal({list, onUpdateList, onClose}) {
    
    const {t} = useTranslation();
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [deleteList, setDeleteList] = useState(false);
    
    const {register, handleSubmit, watch, formState: {errors, defaultValues}, reset, control, setValue} = useForm({
        defaultValues: list,
    });
    
    const watchedTitle = watch("title");
    
    
    useEffect(() => {
        reset(list)
    }, [list])
    
    
    const onSubmit = (list => {
        (list.repeatTitle) ? onUpdateList('delete') : onUpdateList(list);
    });
    
    
    const modalContent = <>
        
        <div className="flex flex-col justify-center">
            
            <div className="sm:mt-8 sm:mx-auto w-full max-w-xl">
                <div className="bg-white pb-4 px-2 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                        <button className="text-3xl" type='button' onClick={onClose}>
                            <MdCancel/>
                        </button>
                        <div className="flex justify-end">
                            <button className="p-3 rounded-xl bg-primary text-white"
                                    type="submit">{t('Form.save')}</button>
                        </div>
                        
                        
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                {t('AddEditList.listName')} *
                            </label>
                            <input
                                {...register("title", {required: true, maxLength: 120})}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                                disabled={deleteList}
                            />
                            {errors.title?.type === 'required' &&
                                <p className="text-red-500">{t('AddEditList.listNameRequired')}</p>}
                            {errors.title?.type === 'maxLength' &&
                                <p className="text-red-500">{t('AddEditList.listNameTooLong')}</p>}
                        </div>
    
    
                        <div className="italic">
                            {t('AddEditList.sharingInfo')}
                        </div>
                        <div>
                            <div>
                                <input
                                    type="radio"
                                    id="0"
                                    value="0"
                                    {...register("sharing", {required: true})}
                                    className="w-4 h-4 accent-primary"
                                />
                                <label htmlFor="0" className="text-sm font-medium text-gray-700 ml-2">
                                    {t('AddEditList.noSharing')}
                                </label>
                            </div>
                           
                            <div>
                                <input
                                    type="radio"
                                    id="1"
                                    value="1"
                                    {...register("sharing", {required: true})}
                                    className="w-4 h-4 accent-primary"
                                />
                                <label htmlFor="1" className="text-sm font-medium text-gray-700 ml-2">
                                    {t('AddEditList.sharingWithFriends')}
                                </label>
                            </div>
                            
                            {errors.sharing?.type === 'required' &&
                                <p className="text-red-500">{t('AddEditList.sharingRequired')}</p>}
                        </div>
                        
                        
                        {list &&
                            <>
                                <button type="button"
                                        onClick={() => setDeleteList(true)}>{t('AddEditList.deleteList')}</button>
                                
                                {deleteList &&
                                    <div>
                                        <label htmlFor="repeatTitle"
                                               className="block text-sm font-medium text-gray-700">
                                            {t('AddEditList.enterListNameToDelete')}
                                        </label>
                                        <input
                                            {...register("repeatTitle", {
                                                required: true,
                                                maxLength: 120,
                                                validate: {notSameTitle: value => value === watchedTitle}
                                            })}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                                        />
                                        {errors.repeatTitle?.type === 'notSameTitle' &&
                                            <p className="text-red-500">    {t('AddEditList.notSameListNames')}</p>}
                                    </div>
                                }
                            </>
                        }
                    
                    
                    </form>
                </div>
                
                <div> * {t('Auth.requiredFields')}</div>
            </div>
        </div>
    </>;
    
    const isSubmittingModalContent = <Spinner/>;
    
    const didSubmitModalContent = <>
        <div>
            <button type='button' onClick={onClose}>{t('Form.close')}</button>
        
        </div>
    </>;
    
    return <Modal onClose={onClose}>
        {!isSubmitting && !didSubmit && modalContent}
        {isSubmitting && isSubmittingModalContent}
        {!isSubmitting && didSubmit && didSubmitModalContent}
    </Modal>
    
}

export default AddEditListModal;
