import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../store/auth-context";
import axios from "axios";
import AddEditListModal from "./AddEditListModal";
import {MessageContext} from "../../store/message-context";
import Spinner from "../UI/Spinner";


const ListEdition = ({listId, onHideModal, onListEdit}) => {
        
        const [isLoading, setIsLoading] = useState(false);
        const [list, setList] = useState(null);
        const authCtx = useContext(AuthContext);
        const messageCtx = useContext(MessageContext);
        
        useEffect(() => {
            if (authCtx.userId) {
                getList();
            }
        }, []);
        
        async function getList() {
            setIsLoading(true);
            
            try {
                const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                    + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);
                
                const data = await response.data;
                setList(data || null);
            } catch (error) {
                messageCtx.setMessage({type: 'error', message: error.message});
            }
            
            setIsLoading(false);
        }
        
        
        async function  editListHandler(listInput) {
            if (listInput === 'delete') {
                try {
    
                    // delete gifts from list
                    const {data : {gifts}} = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                        + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);
                    
                    if(gifts.length > 0){
                        gifts.forEach(gift => {
                            axios.delete('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/gifts/users/'
                                + authCtx.userId + '/' + gift.id + '.json?auth=' + authCtx.token);
                        });
                    }

                    // delete list from users
                    await axios.delete('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                        + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);

                    // delete shared list
                    await axios.delete('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/shared/users/'
                        + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);
    
                    onListEdit(true);
                    onHideModal();
                } catch (error) {
                    messageCtx.setMessage({type: 'error', message: error.message});
                }
            } else {
                
                setIsLoading(true);
                
                try {
                    
                    const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                        + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token, listInput);
                    
                    if(listInput.sharing == '1'){
                     // get list with gift
                      const list =  await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                            + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);
    
                      // add to shared
                        await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/shared/users/'
                            + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token, list.data);
    
                    } else {
                        await axios.delete('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/shared/users/'
                            + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token);
                    }
                    
                    onListEdit(true);
                    
                } catch (error) {
                    messageCtx.setMessage({type: 'error', message: error.message});
                }
                
                setIsLoading(false);
                onHideModal();
            }
            
            
        }
        
        function hideModalHandler() {
            onHideModal();
        }
        
        
        return (
            <>
                {isLoading && <Spinner/>}
                <AddEditListModal list={list} onUpdateList={editListHandler} onClose={hideModalHandler}/>)
            </>
        )
    }
;

export default ListEdition;
