import React, {useContext} from "react";
import {Route, Routes} from "react-router-dom";

import Layout from "./hoc/Layout";
import ListsPage from "./pages/Lists.page";
import HomePage from "./pages/Home.page";
import AuthContextProvider, {AuthContext} from "./store/auth-context";
import LoginPage from "./pages/Login.page";
import SignupPage from "./pages/Signup.page";
import MessageContextProvider from "./store/message-context";
import FriendsPage from "./pages/Friends.page";
import WorldPage from "./pages/World.page";

const ContextLayout = () => {
    const authCtx = useContext(AuthContext);
    
    return (
        <React.Suspense fallback="...is loading">
            <Layout>
                <Routes>
                    <Route path="/" exact element={<HomePage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/signup" element={<SignupPage/>}/>
                    
                    {authCtx.isAuthenticated &&
                        <>
                            <Route path="/lists" element={<ListsPage/>}/>
                            <Route path="/friends" element={<FriendsPage/>}/>
                            <Route path="/world" element={<WorldPage/>}/>
                        </>
                    }
                </Routes>
            </Layout>
        </React.Suspense>
    )
}


const App = () => {
    return (
        <div>
            <MessageContextProvider>
                <AuthContextProvider>
                    <ContextLayout/>
                </AuthContextProvider>
            </MessageContextProvider>
        </div>
    )
}


export default App;
