import AuthForm from "../components/Auth/AuthForm";
import {createUser} from "../util/auth";
import login from "./Login.page";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {MessageContext} from "../store/message-context";
import axios from "axios";
import {AuthContext} from "../store/auth-context";

function SignupPage() {
    
    const [pseudos, setPseudos] = useState(null);
    const messageCtx = useContext(MessageContext);
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    
    
    useEffect(() => {
        fetchPseudos()
    }, [authCtx])
    
    
    
    
    async function signUpHandler({email, password, pseudo}) {
        try {
           const authResponse = await createUser(email, password);
    
            const userId = authResponse.data.localId;
            const token = authResponse.data.idToken;
    
            try {
                
                const pseudoPayload = {[pseudo]: userId};
                await  axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/pseudos.json?auth=' + token,
                    pseudoPayload
                );
                
                const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/' + userId + '.json?auth=' + token,
                    {
                        lists: '',
                        pseudo: pseudo
                    }
                );
    
                navigate('/login');
            } catch (e) {
               const message = e.response.data.error.message ? e.response.data.error.message : e.response.data.error;
                messageCtx.setMessage({'type': 'error', 'message': message});
            }
        } catch (error) {
            messageCtx.setMessage({'type': 'error', 'message': error.response.data.error.message});
        }
        
    }
    
    
    async function fetchPseudos() {
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/pseudos.json');
            setPseudos(Object.keys(response.data));
        } catch (e) {
            messageCtx.setMessage({'type': 'error', 'message': e.response.data.error.message});
        }
    }
    
    
    
    return <AuthForm onAuthenticate={signUpHandler} pseudos={pseudos}/>;
}

export default SignupPage;
