import React from 'react';

const spinner = () => (
    <div className="flex justify-center items-center space-x-2">
        <div
            className="animate-spin inline-block w-8 h-8 border-4 border-primary border-r-transparent rounded-full text-primary"
            role="status">
            <span className="hidden">Loading...</span>
        </div>
    </div>
);

export default spinner;
