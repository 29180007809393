import React, {useContext, useEffect, useState} from 'react';
import {MdChangeCircle} from "react-icons/md";
import styles from './Home.module.css';
import gift from '../assets/gift.jpg';
import highLightTop from '../assets/HighlightTop.svg';
import highLightBottom from '../assets/HighlightBottom.svg';
import {AuthContext} from "../store/auth-context";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HomePage = () => {
    
    
    const [dynamicTitleIndex, setDynamicTitleIndex] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [spin, setSpin] = useState(false);
    
    const {t} = useTranslation();
    
    const authCtx = useContext(AuthContext);
    
    useEffect(() => {
        setIsAuthenticated(authCtx.isAuthenticated)
    }, [authCtx])
    
    function changeTitleHandler() {
        setSpin(true);
        const len = +t('Home.dynamicTitlesLength');
        
        let randomIndex = dynamicTitleIndex;
        while (randomIndex === dynamicTitleIndex) {
            randomIndex = Math.floor(Math.random() * len);
        }
        setDynamicTitleIndex(randomIndex);
        
        
        const spinTimer = setTimeout(() => setSpin(false), 4000);
        return () => clearTimeout(spinTimer);
    }
    
    
    return (
        <>
            <section
                className="pt-24 pb-10 md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-24 md:px-8 px-4 bg-secondary">
                <div className="md:flex-1 md:mr-16">
                    <h1 className="font-pt-serif text-5xl font-bold mb-7">
                        {t('Home.yourLists')} <br/>
                        <span className="relative px-2">
                         <span className={`${styles['bg-underline']} bg-left-bottom bg-no-repeat pb-4 bg-10%
                     overflow-x-hidden whitespace-nowrap
                    `}>
                        {t(`Home.dynamicTitles.${dynamicTitleIndex}`)}
                        </span>
                        <span
                            className={`invisible md:visible absolute -bottom-0 right-0 -top-1 inline-block bg-primary ${spin ? 'w-full animate-type' : ''} will-change`}
                        >
                            <span className="invisible md:visible border-l-4 border-black bg-primary w-full animate-cursor"></span>
                        </span>
                    </span>
                
                        <button type='button' onClick={changeTitleHandler} onAnimationEnd={() => setSpin(false)}><MdChangeCircle className={spin ? 'animate-spin' : ''}/></button>
            
                    </h1>
                    <p className="font-pt-serif font-normal mb-7">
                        {t('Home.text')}
                    </p>
                    <div className="font-montserrat">
                        {isAuthenticated &&
                            <Link to={'/lists'}>
                                <button type='button'
                                        className="bg-black px-6 py-4 rounded-lg border-2 border-black border-solid text-white mr-2 mb-2">
                                    {t('Home.callToAction')}
                                </button>
                            </Link>
                        }
                
                        {!isAuthenticated &&
                            <>
                                <Link to={'/signup'}>
                                    <button type='button'
                                            className="bg-black px-6 py-4 rounded-lg border-2 border-black border-solid text-white mr-2 mb-2">
                                        {t('Home.createAccount')}
                                    </button>
                                </Link>
                                <Link to={'/login'}>
                                    <button type='button'
                                            className="px-6 py-4 border-2 border-black border-solid rounded-lg">
                                        {t('Home.connect')}
                                    </button>
                                </Link>
                            </>
                        }
            
            
                    </div>
                </div>
                <div className="flex justify-around md:block mt-8 md:mt-0 md:flex-1">
                    <div className="relative">
                        <img src={highLightTop} alt="" className="absolute -top-16 -left-10"/>
                    </div>
                    <img src={gift} alt="Gift"/>
                    <div className="relative">
                        <img src={highLightBottom} alt="" className="absolute -bottom-10 -right-6"/>
                    </div>
                </div>
            </section>
            
            <div className="md:flex">
                <section
                    className="py-10 md:mt-0 flex flex-col justify-center text-center px-4">
                    <ul className="ml-5">
                        <li className="flex mb-3">
                            <svg aria-hidden="true" viewBox="0 0 32 32" className="h-8 w-8 flex-none fill-primary">
                                <path
                                    d="M11.83 15.795a1 1 0 0 0-1.66 1.114l1.66-1.114Zm9.861-4.072a1 1 0 1 0-1.382-1.446l1.382 1.446ZM14.115 21l-.83.557a1 1 0 0 0 1.784-.258L14.115 21Zm.954.3c1.29-4.11 3.539-6.63 6.622-9.577l-1.382-1.446c-3.152 3.013-5.704 5.82-7.148 10.424l1.908.598Zm-4.9-4.391 3.115 4.648 1.661-1.114-3.114-4.648-1.662 1.114Z"></path>
                            </svg>
                            <span className="ml-4">Crééz vos listes de cadeaux.</span>
                        </li>
                        <li className="flex mb-3">
                            <svg aria-hidden="true" viewBox="0 0 32 32" className="h-8 w-8 flex-none fill-primary">
                                <path
                                    d="M11.83 15.795a1 1 0 0 0-1.66 1.114l1.66-1.114Zm9.861-4.072a1 1 0 1 0-1.382-1.446l1.382 1.446ZM14.115 21l-.83.557a1 1 0 0 0 1.784-.258L14.115 21Zm.954.3c1.29-4.11 3.539-6.63 6.622-9.577l-1.382-1.446c-3.152 3.013-5.704 5.82-7.148 10.424l1.908.598Zm-4.9-4.391 3.115 4.648 1.661-1.114-3.114-4.648-1.662 1.114Z"></path>
                            </svg>
                            <span className="ml-4">Regardez les listes de vos amis <br/>pour vous inspirer.</span>
                        </li>
                        <li className="flex mb-3">
                            <svg aria-hidden="true" viewBox="0 0 32 32" className="h-8 w-8 flex-none fill-primary">
                                <path
                                    d="M11.83 15.795a1 1 0 0 0-1.66 1.114l1.66-1.114Zm9.861-4.072a1 1 0 1 0-1.382-1.446l1.382 1.446ZM14.115 21l-.83.557a1 1 0 0 0 1.784-.258L14.115 21Zm.954.3c1.29-4.11 3.539-6.63 6.622-9.577l-1.382-1.446c-3.152 3.013-5.704 5.82-7.148 10.424l1.908.598Zm-4.9-4.391 3.115 4.648 1.661-1.114-3.114-4.648-1.662 1.114Z"></path>
                            </svg>
                            <span className="ml-4">Gardez un suivi de la liste <br/> (trouvé, acheté, emballé…). </span>
                        </li>
                        <li className="flex mb-3">
                            <svg aria-hidden="true" viewBox="0 0 32 32" className="h-8 w-8 flex-none fill-primary">
                                <path
                                    d="M11.83 15.795a1 1 0 0 0-1.66 1.114l1.66-1.114Zm9.861-4.072a1 1 0 1 0-1.382-1.446l1.382 1.446ZM14.115 21l-.83.557a1 1 0 0 0 1.784-.258L14.115 21Zm.954.3c1.29-4.11 3.539-6.63 6.622-9.577l-1.382-1.446c-3.152 3.013-5.704 5.82-7.148 10.424l1.908.598Zm-4.9-4.391 3.115 4.648 1.661-1.114-3.114-4.648-1.662 1.114Z"></path>
                            </svg>
                            <span className="ml-4">Partagez ou non vos listes <br/> avec vos liens de parrainage. </span>
                        </li>
                    </ul>
                </section>
                <section
                    className="py-10 md:py-2 md:mt-0 flex flex-col md:flex-1 justify-center text-center bg-gray-200 px-4">
                    <div className="mb-5">
                        <p>Il s'agit de la première version de ce site.</p>
                        <p> N'hésitez pas à me remonter des idées d'améliorations, des problèmes rencontrés ou simplement si vous souhaitez échanger.</p>
                    </div>
                    <Link to="#" onClick={e => {window.location.href = 'mailto:benydevfree@gmail.com'; e.preventDefault();}}>
                        <button type='button'
                                className="px-6 py-4 border-2 border-black border-solid rounded-lg">
                            Me contacter
                        </button>
                    </Link>
                </section>
            </div>
         
        </>
       
    );
};

export default HomePage;
