import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {MessageContext} from "../store/message-context";
import List from "../components/Tag/List";
import {AuthContext} from "../store/auth-context";

const WorldPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const messageCtx = useContext(MessageContext);
    const authCtx = useContext(AuthContext);
    const [tags, setTags] = useState([]);
    const [comingSoon, setComingSoon] = useState(true)
    
    useEffect(() => {
        if (authCtx.userId) {
            fetchTags(authCtx);
        }
    }, [authCtx]);
    
    
    async function fetchTags(auth) {
        setIsLoading(true);
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/tags.json?auth=' + auth.token);
            const data = await response.data;
            setTags(data);
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
        
        setIsLoading(false);
    }
    
    const comingSoonTemplate = ( <div className="p-5">Retrouvez bientôt la possibilité de partager vos cadeaux enregistrés et ainsi de diffuser vos liens de parrainage au monde entier.</div>)
    
    
    
    return (
      <div>
          {comingSoon && comingSoonTemplate}
          {!comingSoon &&  <List friends={tags}></List>}
      </div>
    )
};

export default WorldPage;
