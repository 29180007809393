import {login} from '../util/auth';
import AuthForm from "../components/Auth/AuthForm";
import {useContext, useEffect} from "react";
import {AuthContext} from "../store/auth-context";
import {useNavigate} from "react-router-dom";
import {MessageContext} from "../store/message-context";

function LoginPage() {
    
    const authCtx = useContext(AuthContext);
    const messageCtx = useContext(MessageContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        authCtx.logout();
    }, [])
    
    async function authenticateHandler({email, password}) {
        try {
            const {data} = await login(email, password);
            authCtx.authenticate(data);
            navigate('/');
        } catch (error) {
            messageCtx.setMessage({'type': 'error', 'message': error.response.data.error.message});
            navigate('/login');
        }
    }
    
    return <AuthForm isLogin onAuthenticate={authenticateHandler}/>;
}

export default LoginPage;
