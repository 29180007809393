import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "../UI/Modal";
import {MdCancel} from "react-icons/md";
import Spinner from "../UI/Spinner";
import {Combobox} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import classNames from "classnames";
import {TAGS} from "../Tag/Tags";


function AddEditGiftModal({gift, onUpdateGift, onClose}) {
    
    const {t} = useTranslation();
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [query, setQuery] = useState('');
    
    const {
        register: registerAddEditGift, handleSubmit, watch, formState: {errors, defaultValues}, reset, control
    } = useForm({
        defaultValues: gift
    });
    
    
    useEffect(() => {
        reset(gift);
        setSelectedTags(gift.gifteeTags || []);
    }, [gift])
    
    const filteredTags =
        query === ''
            ? TAGS
            : TAGS.filter((tag) => {
                return tag.toLowerCase().includes(query.toLowerCase())
            })
    
    
    const onSubmit = (gift => {
        gift.gifteeTags = selectedTags;
        gift.default = false;
        onUpdateGift(gift);
    });
    
    
    const modalContent = <>
        
        <div className="flex flex-col justify-center">
            
            <div className="sm:mt-8 sm:mx-auto w-full max-w-xl">
                <div className="bg-white pb-4 px-2 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                        <button className="text-3xl" type='button' onClick={onClose}>
                            <MdCancel/>
                        </button>
                        <div className="flex justify-end">
                            <button className="p-2 sm:p-3 rounded-xl bg-primary text-white"
                                    type="submit">{t('Form.save')}</button>
                        </div>
                        
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.giftName')} *
                            </label>
                            <input
                                {...registerAddEditGift("name", {required: true, maxLength: 120})}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                            />
                            {errors.name?.type === 'required' &&
                                <p className="text-red-500">{t('AddEditGift.giftNameRequired')}</p>}
                            {errors.name?.type === 'maxLength' &&
                                <p className="text-red-500">{t('AddEditGift.giftNameTooLong')}</p>}
                        </div>
                        <div>
                            <label htmlFor="giftee" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.gifteeName')} *
                            </label>
                            <input
                                {...registerAddEditGift("gifteeName", {required: true, maxLength: 120})}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                            />
                            {errors.giftee?.type === 'required' &&
                                <p className="text-red-500">{t('AddEditGift.gifteeNameRequired')}</p>}
                            {errors.giftee?.type === 'maxLength' &&
                                <p className="text-red-500">{t('AddEditGift.gifteeNameTooLong')}</p>}
                        </div>
                        <div>
                            <label htmlFor="gifteeTags" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.gifteeTags')}
                            </label>
                            <Combobox as="div" value={selectedTags} onChange={setSelectedTags} multiple nullable>
                                <div className="relative mt-1">
                                    <Combobox.Input
                                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                                        onChange={(event) => setQuery(event.target.value)}
                                        displayValue={(tags) => tags ? tags.map((tag) => tag).join(', ') : ''}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>
    
                                    
                                    {filteredTags.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredTags.map((tag) => (
                                                <Combobox.Option
                                                    key={tag}
                                                    value={tag}
                                                    className={({active}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            active ? 'bg-secondary text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({active, selected}) => (
                                                        <>
                                                            <span className={classNames('block truncate', selected && 'font-semibold')}>{tag}</span>
                                                            
                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        active ? 'text-white' : 'text-black'
                                                                    )}
                                                                >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.amount')}
                            </label>
                            <input
                                {...registerAddEditGift("amount", {min: 0, max: 10000})}
                                type="number"
                                min="0"
                                max="100000"
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                            />
                            {errors.amount?.type === 'min' &&
                                <p className="text-red-500">  {t('AddEditGift.amountNegative')}</p>}
                            {errors.amount?.type === 'max' &&
                                <p className="text-red-500">{t('AddEditGift.amountTooBig')}</p>}
                        </div>
                        <div>
                            <label htmlFor="market" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.giftMarket')}
                            </label>
                            <input
                                {...registerAddEditGift("market", {maxLength: 120})}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                            />
                            {errors.market?.type === 'maxLength' &&
                                <p className="text-red-500">{t('AddEditGift.giftMarketTooLong')}</p>}
                        </div>
                        <div>
                            <label htmlFor="sponsorLink" className="block text-sm font-medium text-gray-700">
                                {t('AddEditGift.sponsorLink')}
                            </label>
                            <input
                                {...registerAddEditGift("sponsorLink", {maxLength: 120})}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2
                                              placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none
                                              focus:ring-primary sm:text-sm"
                            />
                            {errors.sponsorLink?.type === 'maxLength' &&
                                <p className="text-red-500">{t('AddEditGift.sponsorLinkTooLong')}</p>}
                        </div>
                        
                        <div className="flex justify-center">
                            {/*bought*/}
                            <div className="inline-block mx-2 sm:w-full sm:flex flex-col items-center">
                                <label htmlFor="bought"
                                       className="text-sm font-medium text-gray-700">
                                    {t('AddEditGift.bought')}
                                </label>
                                <input
                                    type="checkbox"
                                    value="true"
                                    {...registerAddEditGift("bought")}
                                    className="p-3 mx-1 md:flex-1 md:px-4 appearance-none rounded-md border border-gray-300
                                                      placeholder-gray-400 shadow-sm
                                                      focus:ring-primary text-primary form-checkbox
                                                      sm:text-sm"
                                />
                            </div>
    
                            {/*wrapped*/}
                            <div className="inline-block mx-2 sm:w-full sm:flex flex-col items-center">
                                <label htmlFor="wrapped"
                                       className="text-sm font-medium text-gray-700">
                                    {t('AddEditGift.wrapped')}
                                </label>
        
                                <input
                                    type="checkbox"
                                    value="true"
                                    {...registerAddEditGift("wrapped")}
                                    className="p-3 mx-1 md:flex-1 md:px-4 appearance-none rounded-md border border-gray-300
                                                      placeholder-gray-400 shadow-sm
                                                      focus:ring-primary text-primary form-checkbox
                                                      sm:text-sm"
                                />
                            </div>
                        </div>
                     
                    
                    </form>
                </div>
                
                <div> * {t('Auth.requiredFields')}</div>
            </div>
        </div>
    </>;
    
    const isSubmittingModalContent = <Spinner/>;
    
    const didSubmitModalContent = <>
        <div>
            <button type='button' onClick={onClose}>{t('Form.close')}</button>
        </div>
    </>;
    
    return <Modal onClose={onClose}>
        {!isSubmitting && !didSubmit && modalContent}
        {isSubmitting && isSubmittingModalContent}
        {!isSubmitting && didSubmit && didSubmitModalContent}
    </Modal>
    
}

export default AddEditGiftModal;
