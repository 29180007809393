import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../store/auth-context";
import AddEditGiftModal from "./AddEditGiftModal";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {MessageContext} from "../../store/message-context";
import Spinner from "../UI/Spinner";


const GiftEdition = ({giftId, onHideModal, onGiftEdit}) => {
        const [isLoading, setIsLoading] = useState(false);
        const [gift, setGift] = useState(null);
        const authCtx = useContext(AuthContext);
        const messageCtx = useContext(MessageContext);
        const {t} = useTranslation();
        
        useEffect(() => {
            if (authCtx.userId) {
                getGift();
            }
        }, []);
        
        async function getGift() {
            setIsLoading(true);
            
            try {
                const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/gifts/users/'
                    + authCtx.userId + '/' + giftId + '.json?auth=' + authCtx.token);
                
                const data = await response.data;
                setGift(data || null);
            } catch (error) {
                messageCtx.setMessage({type: 'error', message: error.message});
            }
            
            setIsLoading(false);
        }
        
        async function addEditGiftHandler(giftInput) {
            
            setIsLoading(true);
            
            try {
                
                const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/gifts/users/'
                    + authCtx.userId + '/' + giftId + '.json?auth=' + authCtx.token, giftInput);
                
                const data = await response.data;
                const giftWithGiftListKeys = (({name, bought, wrapped, gifteeName, gifteeTags, amount, sponsorLink, market }) => ({name, bought, wrapped, gifteeName, gifteeTags, amount, sponsorLink, market}))(data);
                
                setGift(data || null);
                
                onGiftEdit(giftWithGiftListKeys);
                
                const tagGiftInput = (({name: giftName, market: market, amount: giftAmount, sponsorLink: sponsorLink}) => ({
                    giftName,
                    market,
                    giftAmount,
                    sponsorLink
                }))(giftInput);
                
                if (data.gifteeTags) {
                    for (let tag of data.gifteeTags) {
                        const tagsResponse = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/tags/'
                            + tag + '.json?auth=' + authCtx.token, tagGiftInput);
                    }
                }
                
                
            } catch (error) {
                messageCtx.setMessage({type: 'error', message: error.message});
            }
            
            
            setIsLoading(false);
            
            
            onHideModal();
        }
        
        function hideModalHandler() {
            onHideModal();
        }
        
        
        let content = <div>{t('GiftEdition.noResult')}</div>;
        
        if (gift) {
            content = (
                <>
                    <AddEditGiftModal gift={gift} onUpdateGift={addEditGiftHandler} onClose={hideModalHandler}/>}
                </>
            )
        }
        
        
        return (
            <>
                {isLoading && <Spinner/>}
                {content}
            </>
        )
    }
;

export default GiftEdition;
