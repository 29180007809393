import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../store/auth-context";
import axios from "axios";
import AddEditListModal from "./AddEditListModal";
import {MessageContext} from "../../store/message-context";
import Spinner from "../UI/Spinner";


const ListCreation = ({giftId, onHideModal, onListEdit}) => {
        const [isLoading, setIsLoading] = useState(false);
        const authCtx = useContext(AuthContext);
        const messageCtx = useContext(MessageContext);
        
        
        async function addListHandler(listInput) {
            
            setIsLoading(true);
            
            try {
                
                const response = await axios.post('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                    + authCtx.userId + '/lists.json?auth=' + authCtx.token, listInput);
                
                onListEdit(true);
                
            } catch (error) {
                messageCtx.setMessage({type: 'error', message: error.message});
            }
            
            
            setIsLoading(false);
            onHideModal();
        }
        
        function hideModalHandler() {
            onHideModal();
        }
        
        
        return (
            <>
                {isLoading && <Spinner/>}
                <AddEditListModal onUpdateList={addListHandler} onClose={hideModalHandler}/>
            </>
        )
    }
;

export default ListCreation;
