import axios from "axios";


async function authenticate(mode, email, password) {
    const url = `https://identitytoolkit.googleapis.com/v1/accounts:${mode}?key=${process.env.REACT_APP_API_KEY}`;
    
    return axios.post(url, {
        email,
        password,
        returnSecureToken: true
    });
    
}

export function createUser(email, password) {
    return authenticate('signUp', email, password);
}

export function login(email, password) {
    return authenticate('signInWithPassword', email, password);
}

export async function resetPassword(email) {
    
    const url = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${process.env.REACT_APP_API_KEY}`;
    
    return await axios.post(url, {
        email: email,
        requestType: "PASSWORD_RESET"
    });
}
