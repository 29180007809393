import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {MessageContext} from "../../store/message-context";
import {MdArrowRight} from "react-icons/md";
import {Combobox} from "@headlessui/react";
import classNames from "classnames";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import {AuthContext} from "../../store/auth-context";

const FindFriend = () => {
    
    const {t} = useTranslation();
    const [pseudosList, setPseudosList] = useState([]);
    const [selectedPseudo, setSelectedPseudo] = useState(null);
    const [query, setQuery] = useState('');
    const [pseudoMatch, setPseudoMatch] = useState(false);
    const messageCtx = useContext(MessageContext);
    const authCtx = useContext(AuthContext)
    
    useEffect(() => {
        fetchPseudos();
    }, [])
    
    const {
        register: registerFindFriend, handleSubmit, watch, formState: {errors, defaultValues}, reset, control
    } = useForm({});
    
    const filteredPseudos =
        query === ''
            ? pseudosList
            : pseudosList.filter(pseudo => {
                return pseudo.name.toLowerCase().includes(query.toLowerCase())
            })
    
    
    async function fetchPseudos() {
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/pseudos.json?auth=' + authCtx.token);
            const pseudos = response.data;
            
            const pseudosArray = Object.keys(pseudos).map(pseudo => {
                return {'name': pseudo, 'id': pseudos[pseudo]}
            });
            const pseudosWithoutCurrentUser = pseudosArray.filter(pseudo => pseudo.name !== authCtx.userPseudo);
            setPseudosList(pseudosWithoutCurrentUser);
        } catch (e) {
            messageCtx.setMessage({'type': 'error', 'message': e.response.data.error.message});
        }
    }
    
    async function askForFriendHandler(){
        try {
            const receiverPayload = {'fromFriendId': authCtx.userId, 'fromFriendPseudo': authCtx.userPseudo, 'status': 'pending'};
    
            await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + selectedPseudo.id +
                '/' + authCtx.userId + '.json?auth=' + authCtx.token, receiverPayload);
    
            const senderPayload = {'forFriendId': selectedPseudo.id, 'forFriendPseudo': selectedPseudo.name, 'status': 'pending'};
            
            const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + authCtx.userId +
                '/' + selectedPseudo.id + '.json?auth=' + authCtx.token, senderPayload);
            const pseudos = response.data;
        
            const pseudosArray = Object.keys(pseudos).map(pseudo => {
                return {'name': pseudo, 'id': pseudos[pseudo]}
            });
            setPseudosList(pseudosArray);
        } catch (e) {
            messageCtx.setMessage({'type': 'error', 'message': e.response.data.error.message});
        }
    }
    
    return (
        <>
            <div className="mx-10 my-5">
                    <label htmlFor="findFriend" className="block text-sm font-medium text-gray-700">
                        {t('FindFriend.label')}
                    </label>
                <Combobox as="div" value={selectedPseudo} onChange={setSelectedPseudo} nullable>
                    <div className="relative mt-1">
                        <Combobox.Input
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(pseudo) => pseudo?.name}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>
            
                        {filteredPseudos.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredPseudos.map((pseudo) => (
                                    <Combobox.Option
                                        key={pseudo.name}
                                        value={pseudo}
                                        className={({ active }) =>
                                            classNames(
                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                active ? 'bg-secondary text-white' : 'text-gray-900'
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>{pseudo.name}</span>
                                    
                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-black'
                                                        )}
                                                    >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        )}
                    </div>
                </Combobox>
    
                {selectedPseudo &&
                    <button className="flex bg-primary p-2 text-white rounded cursor-pointer my-2" onClick={askForFriendHandler}>{t('FindFriend.askForFriend')}</button>
                }
    
                {
                    !selectedPseudo &&
                    <button className="flex bg-gray-400 p-2 text-white rounded my-2" disabled>{t('FindFriend.askForFriend')}</button>
                }
            </div>
        
        
        </>
    );
}

export default FindFriend;
