import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {MessageContext} from "../../store/message-context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modal from "../UI/Modal";
import {MdCancel} from "react-icons/md";
import Spinner from "../UI/Spinner";
import {Combobox} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import classNames from "classnames";
import axios from "axios";
import {AuthContext} from "../../store/auth-context";


function NotificationsModal({notifications, onClose}) {
    
    const {t} = useTranslation();
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [query, setQuery] = useState(null);
    const authCtx = useContext(AuthContext);
    const messageCtx = useContext(MessageContext);
    
    async function acceptHandler(notif) {
        try {
            // modify friendshiprequest status
            const receiverPayload = {'fromFriendId': notif.fromFriendId, 'fromFriendPseudo': notif.fromFriendPseudo, 'status': 'accepted'};
            
            await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + authCtx.userId +
                '/' + notif.fromFriendId + '.json?auth=' + authCtx.token, receiverPayload);
            
            const senderPayload = {'forFriendId': authCtx.userId, 'forFriendPseudo': authCtx.userPseudo, 'status': 'accepted'};
            
            const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + notif.fromFriendId +
                '/' + authCtx.userId + '.json?auth=' + authCtx.token, senderPayload);
            
            
            // save friendships
            await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendships/' + notif.fromFriendId + '.json?auth=' + authCtx.token, {[authCtx.userId]: authCtx.userPseudo});
            await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendships/' + authCtx.userId + '.json?auth=' + authCtx.token, {[notif.fromFriendId]: notif.fromFriendPseudo});
        } catch (e) {
            messageCtx.setMessage({'type': 'error', 'message': e.response.data.error.message});
        }
    }
    
    
    async function declineHandler(notif) {
        try {
            const receiverPayload = {'fromFriendId': notif.fromFriendId, 'fromFriendPseudo': notif.fromFriendPseudo, 'status': 'declined'};
            
            await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + authCtx.userId +
                '/' + notif.fromFriendId + '.json?auth=' + authCtx.token, receiverPayload);
            
            const senderPayload = {'forFriendId': authCtx.userId, 'forFriendPseudo': 'frompseudo', 'status': 'declined'};
            
            const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/friendrequests/' + notif.fromFriendId +
                '/' + authCtx.userId + '.json?auth=' + authCtx.token, senderPayload);
        } catch (e) {
            messageCtx.setMessage({'type': 'error', 'message': e.response.data.error.message});
        }
    }
    
    const sender = (notif, key) => (
            <>
                <div key={`sender_${key}`} className="border-b border-primary p-4">
                    <div>
                        {t('Notifications.askForFriend')} {notif.forFriendPseudo}
                    </div>
                    <div>
                        {t('Notifications.status')}: {notif.status}
                    </div>
                </div>
            </>
        )
    ;
    
    const receiver = (notif, key) => (
            <>
                
                {notif.status === 'pending' && (
                    <div key={`receiver_${key}`} className="border-b border-primary p-4 flex">
                        <div className="flex-1">
                            {t('Notifications.askForFriend')} {notif.fromFriendPseudo}
                        </div>
                        <div className="flex-end">
                            <button className="bg-primary p-2 mx-1 text-white rounded" onClick={acceptHandler.bind(null, notif)}>{t('Form.accept')}</button>
                            <button className="border border-primary p-2 mx-1 rounded" onClick={declineHandler.bind(null, notif)}>{t('Form.refuse')}</button>
                        </div>
                    </div>
                )}
                {notif.status !== 'pending' && (
                    <div key={`receiver_${key}`} className="border-b border-primary p-4">
                        <div className="">
                            {t('Notifications.askForFriend')} {notif.fromFriendPseudo}
                        </div>
                        <div>
                            {t('Notifications.status')}: {notif.status}
                        </div>
                    </div>
                )}
            
            
            </>
        )
    ;
    
    
    const modalContent = <>
        
        <div className="flex flex-col justify-center">
            
            <div className="mt-8 sm:mx-auto w-full max-w-xl">
                <div className="bg-white pb-4 px-2 shadow sm:rounded-lg sm:px-10">
                    {notifications.length > 0 && notifications.map((notif, key) => (
                        <>
                            {notif.hasOwnProperty('forFriendPseudo') && sender(notif, key)}
                            {notif.hasOwnProperty('fromFriendPseudo') && receiver(notif, key)}
                        </>
                    ))}
                </div>
            </div>
        </div>
    </>;
    
    const isSubmittingModalContent = <Spinner/>;
    
    const didSubmitModalContent = <>
        <div>
            <button type='button' onClick={onClose}>{t('Form.close')}</button>
        </div>
    </>;
    
    return <Modal onClose={onClose}>
        {!isSubmitting && !didSubmit && modalContent}
        {isSubmitting && isSubmittingModalContent}
        {!isSubmitting && didSubmit && didSubmitModalContent}
    </Modal>
    
}

export default NotificationsModal;
