import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../store/auth-context";
import {MessageContext} from "../../store/message-context";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {MdArrowRight, MdRemoveCircle} from "react-icons/md";
import FindFriend from "./FindFriend";

const List = ({friends}) => {
    
    const [pseudo, setPseudo] = useState(null);
    const [friendSelected, setFriendSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [listEdited, setListEdited] = useState(false);
    const authCtx = useContext(AuthContext);
    const messageCtx = useContext(MessageContext);
    const {t} = useTranslation();
    
    
    useEffect(() => {
        if (friendSelected) {
            fetchLists();
        }
        
    }, [friendSelected]);
    
    
    useEffect(() => {
        if (authCtx.userId) {
            setPseudo(friends[authCtx.userId]);
        }
    }, [authCtx])
    
    
    async function fetchLists() {
        setIsLoading(true);
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/shared/users/' + friendSelected + '/lists.json?auth=' + authCtx.token);
            
            const responseData = await response.data;
            const data = Object.keys(responseData).map((key) => {
                return responseData[key];
            });
            setLists(data);
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
        
        setIsLoading(false);
        
        // cleaning
        setListEdited(false);
    }
    
    
    function friendSelectedHandler(friend) {
        setFriendSelected(friend);
    }
    
    
    return (
        <>
            <div className="xl:w-4/5 mx-auto">
                
                
                <FindFriend/>
                
                
                <hr/>
                
                <h2 className="text-2xl">{t('Nav.yourFriends')}</h2>
                
                <ul>
                    {Object.keys(friends).map(friend => <li key={friend} onClick={friendSelectedHandler.bind(null, friend)}><MdArrowRight className="inline-block"/>
                        <button className="cursor-pointer">{friends[friend]}</button>
                    </li>)}
                </ul>
                
                {friendSelected && lists.map((listAndGift, key) => {
                        
                        
                        return (
                            <div key={key} className="overflow-x-auto">
                                <div className="bg-secondary m-2">
                                    <h2 className="text-xl md:text-3xl p-2 underline text-gray-100">{listAndGift.title}
                                    </h2>
                                </div>
                                
                                <table className="w-full border-primary border-2 mx-2 my-10">
                                    
                                    <thead key="header"
                                           className="bg-primary text-white">
                                    <tr>
                                        <th
                                            className="py-3.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-white lg:pl-8">{t('List.gift')}</th>
                                        <th
                                            className="py-3.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-white lg:pl-8">{t('List.giftee')}</th>
                                        <th
                                            className="py-3.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-white lg:pl-8">{t('List.giftAmount')}</th>
                                        <th
                                            className="py-3.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-white lg:pl-8">{t('List.market')}</th>
                                        <th
                                            className="py-3.5 pl-3 pr-2 text-left text-sm font-semibold text-gray-white lg:pl-8">{t('List.sponsorship')}</th>
                                    </tr>
                                    
                                    </thead>
                                    
                                    
                                    <tbody>
                                    
                                    {
                                        listAndGift.gifts.map((gift, giftKey) => {
                                                
                                                return (
                                                    <tr key={giftKey}
                                                        className="">
                                                        <td className="py-3.5 pl-3 pr-2 text-left text-sm lg:pl-8">{gift.name}</td>
                                                        <td className="py-3.5 pl-3 pr-2 text-left text-sm lg:pl-8">{gift.gifteeTags.length > 0 && gift.gifteeTags.join(', ')}</td>
                                                        <td className="py-3.5 pl-3 pr-2 text-left text-sm lg:pl-8">{gift.amount > 0 ? gift.amount + '€' : ''} </td>
                                                        <td className="py-3.5 pl-3 pr-2 text-left text-sm lg:pl-8">{gift.market} </td>
                                                        <td className="py-3.5 pl-3 pr-2 text-left text-sm lg:pl-8">{gift.sponsorLink} </td>
                                                    </tr>
                                                )
                                            }
                                        )
                                    }
                                    </tbody>
                                
                                </table>
                            </div>
                        )
                    }
                )}
            
            </div>
        </>
    );
    
};

export default List;
