import React, {useContext, useEffect} from "react";
import Nav from "../components/UI/Nav";

import classNames from "classnames";
import toast, {Toaster} from "react-hot-toast";
import {MdCancel, MdDoneOutline, MdInfoOutline, MdOutlineClose,} from "react-icons/md";
import styles from "./Layout.module.css";
import {MessageContext} from "../store/message-context";
import {AuthContext} from "../store/auth-context";

const notify = (message) =>
    toast.custom(
        (t) => (
            <div
                className={classNames([
                    styles.notificationWrapper,
                    t.visible ? "top-0" : "-top-96",
                    message.type === 'error' ? "bg-red-500 border-red-600 border-1" : "",
                    message.type === 'info' ? "bg-amber-500 border-amber-600 border-1" : "",
                    message.type === 'success' ? "bg-green-500 border-green-600 border-1" : "",
                ])}
            >
                <div className={classNames([
                    styles.iconWrapper,
                    "text-white"
                ])}
                >
                    {message.type === 'error' && <MdCancel/>}
                    {message.type === 'info' && <MdInfoOutline/>}
                    {message.type === 'success' && <MdDoneOutline/>}
                </div>
                <div className={classNames([
                    styles.contentWrapper,
                    "text-white"
                ])}>
                    {message.message}
                </div>
                <div className={classNames([
                    styles.closeIcon,
                    "text-white"
                ])}
                     onClick={() => toast.dismiss(t.id)}>
                    <MdOutlineClose/>
                </div>
            </div>
        ),
        {id: "unique-notification", position: "top-center"}
    );


const Layout = ({children}) => {
    
    const messageCtx = useContext(MessageContext);
    
    useEffect(() => {
        if (!messageCtx.message) {
            return;
        }
        notify(messageCtx.message);
    }, [messageCtx])
    
    return (
        <div className="w-full overflow-x-hidden h-screen">
            <header>
                <Nav/>
            </header>
            
            <Toaster/>
            
            <main className="mt-20">{children}</main>
        </div>
    );
}


export default Layout;
