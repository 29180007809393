import ListFromList from '../List/List'
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../store/auth-context";
import {MessageContext} from "../../store/message-context";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {MdArrowRight, MdRemoveCircle} from "react-icons/md";
import {useFieldArray} from "react-hook-form";

const List = ({friends}) => {
    
    const {t} = useTranslation();
    const [selectedTag, setSelectedTag] = useState(null);
    const [gifts, setGifts] = useState([]);
    
    useEffect(() => {
        if (selectedTag) {
            fetchGifts();
        }
    }, [selectedTag]);
    
    const fetchGifts = async () => {
        setGifts(friends[selectedTag]);
    };
    
    function selectTagHandler(tagId) {
        setSelectedTag(tagId);
    }
    
    return (<>
            <div className="xl:fixed">
                <div>Filter by giftee tag</div>
                <ul className="m-2">
                {Object.keys(friends).map((tag, key) => (<li key={key} onClick={selectTagHandler.bind(null, tag)}><MdArrowRight className="inline-block"/><button className="cursor-pointer">{tag}</button></li>))}
                </ul>
            </div>
          
            
            <div>
                <div className="overflow-x-auto mx-auto xl:w-4/5">
                    
                    
                    <table className="w-full">
                        
                        <thead key="header"
                               className="bg-primary text-white">
                        <tr>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.gift')}</th>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.giftee')}</th>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.title')}</th>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.giftAmount')}</th>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.market')}</th>
                            <th
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-white sm:pl-6 lg:pl-8">{t('List.sponsorship')}</th>
                        </tr>
                        
                        </thead>
                        
                        <tbody>
                        {gifts.map((tag, key) => {
                            return (<tr
                                    className="border-b border-primary">
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8">{tag.giftName}</td>
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8">{tag.gifteeTags ? tag.gifteeTags.join(', ') : ''}</td>
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8">{tag.listName}</td>
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8">{tag.giftAmount > 0 ? tag.giftAmount + '€' : ''} </td>
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8">{tag.market} </td>
                                    <td className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 lg:pl-8"><a href={tag.sponsorLink}>{tag.sponsorLink}</a></td>
                                </tr>)
                        })}
                        
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    
    );
    
};

export default List;
