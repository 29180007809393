import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../store/auth-context";
import List from "../components/List/List";
import axios from "axios";
import ListCreation from "../components/List/ListCreation";
import Spinner from "../components/UI/Spinner";
import {useTranslation} from "react-i18next";
import {MessageContext} from "../store/message-context";

const ListsPage = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [isNewListMode, setIsNewListMode] = useState(false);
    const [listEdited, setListEdited] = useState(false);
    const authCtx = useContext(AuthContext);
    const messageCtx = useContext(MessageContext);
    const {t} = useTranslation();
    
    useEffect(() => {
        if (authCtx.userId) {
            fetchLists(authCtx);
        }
    }, [authCtx, listEdited]);
    
    
    async function fetchLists(auth) {
        setIsLoading(true);
        try {
            const response = await axios.get('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/' + auth.userId + '.json?auth=' + auth.token);
            
            const data = await response.data;
            setLists(data.lists || []);
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
        
        setIsLoading(false);
        
        // cleaning
        setListEdited(false);
    }
    
    
    function addListHandler() {
        setIsNewListMode(true)
    }
    
    function listEditedHandler() {
        setListEdited(true)
    }
    
    
    const contentTemplate = (
        <>
            {(!lists || lists.length == 0) && <div className="p-5 text-xl">{t('ListsPage.noResult')}</div>}
            
            <button
                type='button'
                className="border-primary border-2 rounded-2xl ml-5 p-2 xl:mt-20 xl:fixed"
                onClick={addListHandler}
            >
                + {t('ListsPage.addList')}
            </button>
            
            <div className="mt-10">
                {Object.keys(lists).map((listId) => {
                        return (
                            <List key={listId} listInput={lists[listId]} listId={listId} onListEdit={listEditedHandler}/>
                        )
                    }
                )}
            </div>
    
            {isNewListMode && <ListCreation onHideModal={() => setIsNewListMode(false)}
                                            onListEdit={listEditedHandler}/>}
         
        </>
    )
    
    
    return (<>
            <h1 className="text-primary text-4xl p-5 xl:fixed">
                {t('ListsPage.yourLists')}
            </h1>
            
            {isLoading && <Spinner/>}
            
            {!isLoading &&
                contentTemplate
            }
        </>
    
    
    );
};

export default ListsPage;
