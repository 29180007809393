import React, {useContext, useEffect, useState} from 'react';
import {MdModeEditOutline, MdOutlineAddCircle, MdRemoveCircle, MdSave} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../store/auth-context";
import axios from "axios";
import {useFieldArray, useForm} from "react-hook-form";
import GiftEdition from "../Gift/GiftEdition";
import ListEdition from "./ListEdition";
import {useTranslation} from "react-i18next";
import {MessageContext} from "../../store/message-context";
import Spinner from "../UI/Spinner";
import {DEFAULT_GIFT} from "../../util/defaultGift";
import classNames from "classnames";
import Modal from "../UI/Modal";

const List = ({listInput, listId, onListEdit}) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [sureModal, setSureModal] = useState(false);
    const [removeGiftIndex, setRemoveGiftIndex] = useState(null);
    const [isGiftEditMode, setIsGiftEditMode] = useState(false);
    const [isListEditMode, setIsListEditMode] = useState(false);
    const [giftIdForEdit, setGiftIdForEdit] = useState(null);
    const [giftKeyToEdit, setGiftKeyToEdit] = useState(null);
    const [listAmount, setListAmount] = useState(null);
    const authCtx = useContext(AuthContext)
    const messageCtx = useContext(MessageContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    useEffect(() => {
        if (listInput.gifts) {
            const totalAmount = listInput.gifts.reduce((acc, currentVal) => {
                if (currentVal.amount <= 0 || currentVal.default) {
                    return acc;
                }
                return acc + +currentVal.amount
            }, 0);
            setListAmount(totalAmount);
        }
        
        reset(listInput)
    }, [listInput]);
    
    
    const {
        register,
        handleSubmit: handleSubmitList,
        watch,
        formState: {errors, defaultValues},
        reset,
        control,
        getValues
    } = useForm({
        defaultValues: listInput
    });
    
    const {fields, append, prepend, remove, swap, move, insert, update} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "gifts", // unique name for your Field Array
        keyName: 'fieldUid'
    });
    
    const onSubmit = (list => {
        saveList(list);
    });
    
    
    function editListHandler() {
        setIsListEditMode(true)
    }
    
    
    async function saveList(list) {
        setIsLoading(true);
        
        try {
            
            const response = await axios.patch('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/users/'
                + authCtx.userId + '/lists/' + listId + '.json?auth=' + authCtx.token, list);
            

            const data = await response.data;
            setList(data || null);
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
        
        setIsLoading(false);
    }
    
    
    function editGiftHandler(event, giftId, giftKey) {
        event.stopPropagation();
        
        setGiftIdForEdit(giftId);
        setGiftKeyToEdit(giftKey);
        setIsGiftEditMode(true)
    }
    
    function giftEditedHandler(giftUpdated) {
        giftUpdated.default = false;
        const giftToUpdate = fields.filter(field => field.id === giftIdForEdit);
        const updatedGift = {...giftToUpdate[0], ...giftUpdated};
        
        update(giftKeyToEdit, updatedGift);
        const values = getValues();
        saveList(values)
        
    }
    
    async function addGiftHandler(listId) {
        setIsLoading(true);
        
        try {
            const gift = {
                "amount": DEFAULT_GIFT.amount,
                "bought": false,
                "market": DEFAULT_GIFT.market,
                "name": DEFAULT_GIFT.name,
                "wrapped": false,
                "sponsorLink": DEFAULT_GIFT.sponsorLink,
                "listId": listId,
                "default": true,
            };
            
            const addGiftResponse = await axios.post('https://gift-list-88c7e-default-rtdb.europe-west1.firebasedatabase.app/gifts/users/' + authCtx.userId + '.json?auth=' + authCtx.token, gift);
            
            const giftId = addGiftResponse.data.name;
            
            const giftList = {
                "bought": false,
                "name": DEFAULT_GIFT.name,
                "amount": DEFAULT_GIFT.amount,
                "wrapped": false,
                "id": giftId,
                "default": true
            };
            
            append(giftList);
            const values = getValues();
            saveList(values)
            
        } catch (error) {
            messageCtx.setMessage({type: 'error', message: error.message});
        }
        
        setIsLoading(false);
    }
    
    function removeGiftHandler(giftIndex) {
        
        setSureModal(true);
        setRemoveGiftIndex(giftIndex)
    }
    
    function confirmRemoveHandler(confirm){
        if(confirm){
            remove(removeGiftIndex);
    
            const values = getValues();
            saveList(values)
        }
      
        setRemoveGiftIndex(null);
        setSureModal(false);
    }
    
    function closeSureModal(){
        setSureModal(false);
    }
    
    function listEditedHandler() {
        onListEdit(true);
    }
    
    function clickDefaultGiftHandler(def){
        if(def){
            window.open(DEFAULT_GIFT.sponsorLink, '_blank', 'noopener,noreferrer');
        }
    }
    
    
    return (
        <>
            {isLoading && <Spinner/>}
            
            <form onSubmit={handleSubmitList(onSubmit)}>
                <div className="border-primary border-2 xl:w-4/5 mx-auto mb-20">
                    <div className="bg-secondary">
                        <h2 className="text-xl md:text-3xl p-2 underline text-gray-100 flex">{listInput.title}
                            <div className="md:mx-5 flex flex-1 justify-between ">
                                <button type='button' className="ml-2 md:ml-5" onClick={editListHandler}>
                                    <MdModeEditOutline/>
                                </button>
                            </div>
                        </h2>
                    </div>
                    
                    <div className="w-full flex p-2">
                        <div className="flex-1">
                            
                            <div key="header"
                                 className="my-1 md:mx-4 md:my-3 md:p-3 flex border-b-2 border-primary flex">
                                <div
                                    className={`py-1 md:py-3 md:pl-3 md:-ml-3 bg-primary text-white`}>{t('List.giftee')}</div>
                                <div
                                    className="py-1 px-2 md:px-5 md:py-3 flex-1 bg-primary text-white">{t('List.gift')}</div>
                                <div
                                    className="py-1 px-2 md:px-10 md:py-3 bg-primary text-white">{t('List.giftAmount')}</div>
                                <div
                                    className="py-1 px-2 md:px-2 md:py-3 bg-primary text-white md:w-24 text-center">{t('List.status')}</div>
                                <button type='button'
                                        className="py-1 md:pl-5 md:py-3 md:pr-1 md:mr-4 bg-primary text-white">{t('List.action')}
                                </button>
                                <button type="button" onClick={addGiftHandler.bind(null, listId)}
                                        className="py-1 text-primary text-lg md:text-2xl">
                                    <MdOutlineAddCircle/>
                                </button>
                            </div>
                            
                            {
                                fields &&
                                fields.length > 0 &&
                                fields.map((gift, giftKey) => {
                                        
                                        let status = <div
                                            className="p-1 bg-red-500 text-white text-[0px] w-6 h-6 text-center md:text-sm md:w-full md:h-full">{t('List.noFound')}</div>;
    
    
                                    if (gift.default) {
                                        status = <div
                                            className="p-1 bg-gray-400 text-white text-[0px] w-6 h-6 text-center md:text-sm md:w-full md:h-full">{t('List.idea')}</div>;
                                    }
                                        else if (gift.bought && gift.wrapped) {
                                            status = <div
                                                className="p-1 bg-amber-400 text-white text-[0px] w-6 h-6 text-center md:text-sm md:w-full md:h-full border-primary border-2">{t('List.complete')}</div>;
                                        } else if (gift.amount > 0 && !gift.bought) {
                                            status =
                                                <div
                                                    className="p-1 border-2 border-red-500 text-red-500 text-[0px] w-6 h-6 text-center md:text-sm md:w-full md:h-full">{t('List.noBought')}</div>;
                                        } else if (gift.amount > 0 && !gift.wrapped) {
                                            status =
                                                
                                                <div
                                                    className="p-1 bg-green-700 text-white text-[0px] w-6 h-6 text-center md:text-sm md:w-full md:h-full"
                                                
                                                >{t('List.bought')}</div>
                                        }
                                        
                                        return (
                                            <div key={giftKey}
                                                 className=""
                                                 className={classNames(
                                                     {
                                                         'p-1 md:mx-4 md:my-3 md:p-3 flex border-b-2 border-primary flex ':true,
                                                         'text-gray-400 cursor-pointer': gift.default
                                                 
                                                     }
                                                 )}
                                                onClick={clickDefaultGiftHandler.bind(null, gift.default)}
                                            >
                                                <div className="text-gray-400 italic">{gift.gifteeName}</div>
                                                <div className="mx-2 md:mx-5 flex-1 ">{gift.name}</div>
                                                <div className="mx-2 md:mx-5 m-auto">{gift.amount > 0 ? gift.amount + '€' : ''} </div>
                                                <div className="mx-2 md:mx-2 md:w-24 m-auto">{status}</div>
                                                <button type='button' className="mx-2 md:mx-5 text-black"
                                                        onClick={(event) => editGiftHandler(event, gift.id, giftKey)}>{t('Form.edit')}
                                                </button>
                                                <button type="button" onClick={removeGiftHandler.bind(null, giftKey)}
                                                        className="text-primary text-lg md:text-2xl">
                                                    <MdRemoveCircle/>
                                                </button>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                    <span className="float-right rounded border-2 border-primary p-1 md:p-2 md:text-2xl">Total: {listAmount} €</span>
                </div>
            </form>
            
            {isListEditMode && <ListEdition listId={listId} onHideModal={() => setIsListEditMode(false)}
                                            onListEdit={listEditedHandler}/>}
            
            {isGiftEditMode && <GiftEdition giftId={giftIdForEdit} onHideModal={() => setIsGiftEditMode(false)}
                                            onGiftEdit={giftEditedHandler}/>}
    
            { sureModal &&
                (
                    <Modal onClose={closeSureModal}>
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl p-2">{t('Form.sure')}</h2>
                            <div>
                                <button className="bg-primary p-2 mx-1 text-white rounded" onClick={confirmRemoveHandler.bind(null, true)}>{t('Form.confirm')}</button>
                                <button className="border border-primary p-2 mx-1 rounded" onClick={confirmRemoveHandler.bind(null, false)}>{t('Form.cancel')}</button>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </>
    
    );
};

export default List;
