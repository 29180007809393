import {createContext, useState} from 'react';

const defaultState = {
    message: {},
    setMessage: () => {
    }
};


export const MessageContext = createContext(defaultState);

function MessageContextProvider({children}) {
    const [messageState, setMessageState] = useState();
    
    
    function setMessage(message) {
        setMessageState(message);
    }
    
    const value = {
        message: messageState,
        setMessage,
    }
    
    
    return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
}

export default MessageContextProvider;
