import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import {DateTime} from "luxon";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";


i18n
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        interpolation: {
            escapeValue: false, // react already safes from xss
            format: (value, format, lng) => {
                // legacy usage
                if (value instanceof Date) {
                    return DateTime.fromJSDate(value)
                        .setLocale(lng)
                        .toLocaleString(DateTime[format]);
                }
                return value;
            },
        }
    });

export default i18n;
